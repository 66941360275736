<script>
import { hasRouteTab, hasRouteDay } from "@/mixins/routerParams";
import DaybookAppBarMixin from "./DaybookAppBarMixin";
import ManagerContent from "@/components/core/ManagerContent";

export default {
  name: "DayViewAppBarMobile",
  components: { ManagerContent },
  mixins: [hasRouteTab, hasRouteDay, DaybookAppBarMixin],
};
</script>

<template>
  <VxAppBar title="Daybook">
    <template #title>
      <VxAppBarDate v-model="dayDate" />
    </template>

    <template #rightMenuItems>
      <v-list-item
        v-if="!inAssignMode"
        :key="'assign-task'"
        ripple
        @click="
          $router.replace({ query: { assignMode: true }, preserveQuery: true })
        "
      >
        <v-list-item-title v-text="'Assign tasks'" />
      </v-list-item>
      <v-list-item
        v-if="inAssignMode"
        :key="'not-assign-task'"
        ripple
        @click="
          $router.replace({
            query: { assignMode: undefined },
            preserveQuery: true,
          })
        "
      >
        <v-list-item-title v-text="'Exit Assign Mode'" />
      </v-list-item>
      <template v-for="(item, idx) in computedItems">
        <ManagerContent v-if="item.managerOnly" :key="idx">
          <v-list-item :key="idx" ripple :to="item.to">
            <v-list-item-title v-text="item.title" />
            <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
            <v-list-item-action v-if="item.icon">
              <v-icon v-text="item.icon" />
            </v-list-item-action>
          </v-list-item>
        </ManagerContent>
        <v-list-item v-else :key="idx" ripple :to="item.to">
          <v-list-item-title v-text="item.title" />
          <v-list-item-subtitle v-if="item.subtitle" v-text="item.subtitle" />
          <v-list-item-action v-if="item.icon">
            <v-icon v-text="item.icon" />
          </v-list-item-action>
        </v-list-item>
      </template>
    </template>

    <template #extension>
      <v-tabs v-model="tab" grow background-color="transparent">
        <v-tab
          href="#kpis"
          :ripple="false"
          @click="$router.push('?t=kpis')"
          v-text="`KPIs`"
        />
        <v-tab
          href="#tasks"
          :ripple="false"
          @click="$router.push('?t=tasks')"
          v-text="`Tasks`"
        />
        <v-tab
          href="#notes"
          :ripple="false"
          @click="$router.push('?t=notes')"
          v-text="`Notes`"
        />
        <v-tab
          href="#shifts"
          :ripple="false"
          @click="$router.push('?t=shifts')"
          v-text="`Shifts`"
        />
      </v-tabs>
    </template>
  </VxAppBar>
</template>
